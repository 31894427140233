import { useCallback, useMemo } from 'react';

import { FindFolder, IsNotEmpty, ShortPlaygroundInfo, ShortProjectInfo, SynopticExtendedInfo, UUID } from '@dametis/core';

import { getLocalStorageItem, setLocalStorageItem } from 'functions/localStorage';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { TypedThunk, useSelector } from 'store';
import { useFolders } from 'store/api/folders';
import { usePlaygrounds } from 'store/api/playgrounds';
import { useProjects } from 'store/api/projects';
import { useReports } from 'store/api/reports';
import { useSynoptics } from 'store/api/synoptics';
import { LastEntities, LastEntityType } from 'types/drawer';

const defaultLastEntities: LastEntities = {
  [LastEntityType.PLAYGROUNDS]: [],
  [LastEntityType.REPORTS]: [],
  [LastEntityType.SYNOPTICS]: [],
  [LastEntityType.PROJECTS]: [],
  [LastEntityType.LEGO_FOLDERS]: [],
};

export const useAddLastEntity = () => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const [, setLastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });

  return useCallback(
    (entityType: LastEntityType, entity: { uuid: string }) => {
      setLastEntities(prevLastEntities => {
        return {
          ...prevLastEntities,
          // Add entity to the beginning of the list, and remove any duplicates
          [entityType]: [entity, ...prevLastEntities[entityType].filter(e => e.uuid !== entity.uuid)].slice(0, 10),
        };
      });
    },
    [setLastEntities],
  );
};

export const removeLastEntity =
  (entityType: LastEntityType, entityId: UUID): TypedThunk<void> =>
  (dispatch, getState) => {
    const userId = getState().auth.user?.uuid;
    const groupId = getState().auth.selectedGroup?.uuid;
    const siteId = getState().auth.selectedSite?.uuid;
    const lastEntities = getLocalStorageItem<LastEntities>('lastEntities', { userId, groupId, siteId });

    const newLastEntities = {
      ...lastEntities,
      [entityType]: lastEntities?.[entityType].filter(entity => entity.uuid !== entityId),
    };

    setLocalStorageItem('lastEntities', newLastEntities, { userId, groupId, siteId });
  };

const synopticsEmptyArray: SynopticExtendedInfo[] = [];

export const useLastSynoptics = (): SynopticExtendedInfo[] => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: synoptics = synopticsEmptyArray } = useSynoptics();
  const [lastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });
  return useMemo(
    () => lastEntities.synoptics.map(lastSynoptic => synoptics.find(synoptic => synoptic.uuid === lastSynoptic.uuid)).filter(IsNotEmpty),
    [lastEntities.synoptics, synoptics],
  );
};

const playgroundsEmptyArray: ShortPlaygroundInfo[] = [];

export const useLastPlaygrounds = () => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: playgrounds = playgroundsEmptyArray } = usePlaygrounds();
  const [lastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });
  return useMemo(
    () =>
      lastEntities.playgrounds
        .map(lastPlayground => playgrounds.find(playground => playground.uuid === lastPlayground.uuid))
        .filter(IsNotEmpty),
    [lastEntities.playgrounds, playgrounds],
  );
};

const reportsEmptyArray: [] = [];

export const useLastReports = () => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: reports = reportsEmptyArray } = useReports();
  const [lastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });
  return useMemo(
    () => lastEntities.reports.map(lastReport => reports.find(report => report.uuid === lastReport.uuid)).filter(IsNotEmpty),
    [lastEntities.reports, reports],
  );
};

const projectsEmptyArray: ShortProjectInfo[] = [];

export const useLastProjects = () => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: projects = projectsEmptyArray } = useProjects();
  const [lastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });
  return useMemo(
    () => lastEntities.projects.map(lastProject => projects.find(project => project.uuid === lastProject.uuid)).filter(IsNotEmpty),
    [lastEntities.projects, projects],
  );
};

export const useLastLegoFolders = () => {
  const userId = useSelector(state => state.auth.user?.uuid);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: rootFolder = null } = useFolders();
  const [lastEntities] = useLocalStorage<LastEntities>('lastEntities', defaultLastEntities, { userId, groupId, siteId });

  return useMemo(
    () =>
      lastEntities.legoFolders
        .map(lastLegoFolder => {
          if (!rootFolder) {
            return undefined;
          }
          return FindFolder(rootFolder, folder => folder.uuid === lastLegoFolder.uuid);
        })
        .filter(IsNotEmpty),
    [lastEntities.legoFolders, rootFolder],
  );
};
