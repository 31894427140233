import { TabType } from '@dametis/core';

import { ThresholdDirection } from '../../types';

export default {
  title: {
    playground: 'Playgrounds',
    playground_withName: 'Playground "{{name}}"',
    template: 'Modelo de playground',
    template_withName: 'Modelo de playground "{{name}}"',
    xAxes: 'Ejes X',
    yAxes: 'Ejes Y',
    xAxis: 'Eje X',
    yAxis: 'Eje Y',
    scalar: 'Variable explicada',
    explanatoryVar: 'Variables explicativas',
    drawArea: 'Zonas',
    maths: 'Matemáticas',
    create: 'Crear',
    home: 'Elija una primera pestaña para agregar a este playground:',
    noTabs: 'Ninguna pestaña',
    variableStats: 'Estadísticas',
    variableSettings: 'Parámetros',
    variableStyle: 'Estilo',
    variableThreshold: 'Umbral',
    variableModelisation: 'Modelado',
    variableCovariances: 'Variables influyentes',
    regression: 'Regresión',
    regression_NONE: 'Ninguna',
    regression_LINEAR: 'Regresión lineal',
    regression_POLYNOMIAL2: 'Regresión polinomial X²',
    regression_POLYNOMIAL3: 'Regresión polinomial X³',
    regression_POLYNOMIAL4: 'Regresión polinomial X⁴',
    regression_EXPONENTIAL: 'Regresión exponencial',
    regression_LOGARITHMIC: 'Regresión logarítmica',
    export: 'Exportar',
    data: 'Datos',
    subperiods: 'Subperíodos',
    tabQueryLanguage: "Parámetros de la pestaña '{{name}}'",
    createModel: 'Nuevo modelo',
    editModel: 'Modificar el modelo',
    customPeriods: 'Períodos personalizados',
    addCommentToVar: 'Agregar un comentario a la variable "{{var}}"',
  },
  subtitle: {
    variables: 'Variables',
    timeRange: 'Intervalo de tiempo',
    selectedVariable: '{{name}}',
    home1: 'o haga clic en el botón',
    home2: 'para los otros tipos.',
    variable: 'Variable',
    linearRegFormula: 'Ecuación',
    linearRegR2: 'r²',
    rules: 'Reglas',
    addTyVariable: 'Agregar una primera variable',
    addBarVariable: 'Agregar una primera variable',
    addHistogramVariable: 'Seleccionar una variable',
    addYXyVariable: 'Agregar una primera variable al eje Y',
    addXXyVariable: 'Seleccionar una variable para el eje X',
    noChart: 'Modificar este playground para agregar variables',
    exportFormat: 'Formato del archivo',
    minValue: 'mín: {{min}}',
    maxValue: 'máx: {{max}}',
  },
  tab: {
    data: 'Datos',
    tools: 'Herramientas',
    statistics: 'Estadísticas',
    threshold: 'Umbral',
    modelisation: 'Modelado',
    customPeriods: 'Períodos',
  },
  button: {
    edit: 'Modificar',
    save: 'Guardar',
    saved: 'Guardado',
    saveAs: 'Duplicar',
    delete: 'Vaciar',
    openInPlayground: 'Playground',
    previewBatch: 'Vista previa',
    createTab: 'Crear',
    create: 'Crear',
    cancel: 'Cancelar',
    download: 'Descargar',
    configuration: 'Configuración',
    addVariable: 'Agregar',
    addArea: 'Agregar',
    addStyledRule: 'Regla',
    addStyleConfiguration: 'Lista',
    createCalculatedVariable: 'Variable calculada',
    closeQueryLanguageDialog: 'Ok',
    createModel: 'Nuevo modelo',
    saveModel: 'Guardar',
    exportModel: 'Exportar este modelo',
    addPeriod: 'Agregar',
    share: 'Compartir',
    editCovariances: 'Modificar la lista',
    saveStyleConfiguration: 'Lista',
    png: 'PNG',
  },
  text: {
    addTabOfType: 'Agregar una pestaña de tipo:',
    tabTypes_XY_CHART: 'Gráfico XY',
    tabTypes_TY_CHART: 'Gráfico temporal',
    tabTypes_BAR_CHART: 'Diagrama de barras',
    tabTypes_PIE_CHART: 'Diagrama circular',
    tabTypes_HISTOGRAM: 'Histograma',
    tabTypes_LINEAR_REG: 'Regresión lineal',
    tabTypes_LIVE_CHART: 'Gráfico en vivo',
    tabTypes_MULTI_LINEAR_REG: 'Regresión lineal múltiple',
    tabTypes_TABLE: 'Tabla de valores',
    [`tabTypes_${TabType.NONE}`]: 'Ningún',
    noVariables: 'Sin variables',
    noVariable: 'Sin variable',
    xIndex: 'El índice se utiliza como eje X',
    noStyledRules: 'Sin reglas de estilo',
    mean: 'Media',
    min: 'Mínimo',
    max: 'Máximo',
    integral: 'Integral',
    stddev: 'Desviación estándar',
    gap: 'Diferencia',
    thresholdValue: 'Valor',
    thresholdDelay: 'Retrasos',
    thresholdNb: 'Número de excesos',
    thresholdTime: 'Duración total de excesos',
    thresholdIntegral: 'Integral',
    threshold: {
      [ThresholdDirection.ABOVE]: 'Encima',
      [ThresholdDirection.UNDER]: 'Debajo',
    },
    from: 'Inicio',
    fromDate: 'Inicio: {{date}}',
    to: 'Fin',
    toDate: 'Fin: {{date}}',
    date: 'Fecha',
    newGroup: 'Nuevo grupo',
    newPlayground: 'Nueva playground',
    newModel: 'Nuevo modelo',
    duration: 'Duración',
    noModels: 'Ningún modelo',
    multipleBatchTY: 'Períodos múltiples',
    modelHelper: 'Las regresiones lineales múltiples ahora están disponibles en la nueva página',
    commentDate: 'El {{date}}',
  },
  input: {
    label: {
      name: 'Nombre',
      period: 'Período',
      formula: 'Fórmula',
      groupBy: 'Paso',
      from: 'Inicio',
      to: 'Fin',
      customTimeRange: 'Personalizado para esta pestaña',
      previewMin: 'mín',
      previewMax: 'máx',
      step: 'Paso',
      placeholderVariable: 'Seleccionar una variable...',
      xXyVariable: 'Variable del eje X',
      yXyVariable: 'Variables del eje Y',
      selectAxis: 'Seleccionar un eje',
      areaRangeLeft: 'Izquierda',
      areaRangeRight: 'Derecha',
      histogramVariable: 'Variable',
      batch: 'Batch',
      min: 'mín',
      Min: 'Mínimo',
      max: 'máx',
      Max: 'Máximo',
      format: 'Formato',
    },
    placeholder: {
      previewMin: 'auto',
      previewMax: 'auto',
      step: 'auto',
      selectBatch: 'Seleccionar...',
      excel: 'Excel',
    },
    value: {
      noVariable: 'Ninguna variable',
      queryLanguageDisabledTitle: 'Una pestaña para el bloque',
      queryLanguageDisabledDescription: 'Por defecto. Podrás seleccionar las variables del bloque.',
      queryLanguageEnabledTitle: 'Una pestaña por hijo del bloque',
      queryLanguageEnabledDescription:
        'Durante la edición, podrás seleccionar las variables de los hijos del bloque. Después de guardar, la pestaña se multiplicará por cada hijo del bloque.',
      ofAllTypes: 'Todos los hijos',
      onlyOfType: 'Solo de tipo...',
      standard: 'Variable',
      index: 'Índice',
    },
    helperText: {
      groupBy: 'Seleccionar un subperíodo',
      batch: 'Seleccionar un batch',
    },
  },
  tooltip: {
    createPlayground: 'crear el playground',
    savePlayground: 'guardar el playground',
    duplicatePlayground: 'duplicar el playground',
    sharePlayground: 'compartir el playground',
    deletePlayground: 'vaciar el playground',
    timeRangeDisabledFor_customTimeRange:
      'El intervalo de tiempo global está desactivado para esta pestaña debido al intervalo de tiempo personalizado activado en Datos > Eje X.',
    timeRangeDisabledFor_customPeriod:
      'El intervalo de tiempo global está desactivado para esta pestaña debido al intervalo de tiempo personalizado activado en Períodos > Períodos personalizados.',
    zoomOut: 'Deszoom',
    showTooltips: 'Mostrar las tags informativas',
    hideTooltips: 'Ocultar las tags informativas',
    showYAxis: 'Mostrar las ordenadas',
    hideYAxis: 'Ocultar las ordenadas',
    disconnectNulls: 'Mantener los huecos de datos',
    connectNulls: 'Rellenar los huecos de datos',
    connectNullsTemporaryUnavailable: 'Esta funcionalidad no está disponible temporalmente',
    fullscreen: 'Modo pantalla completa',
    exitFullscreen: 'Salir del modo pantalla completa',
    addBarVariable: 'Agregar una primera variable',
    addTyVariable: 'Agregar una primera variable',
    addHistogramVariable: 'Seleccionar una variable',
    addYXyVariable: 'Agregar una primera variable al eje Y',
    addXXyVariable: 'Seleccionar una variable para el eje X',
    dragDrop: 'Arrastrar y soltar',
    closeTab: 'Cerrar la pestaña',
    cantCreateTab: 'No puedes crear más de 5 nuevas pestañas',
    displayGroup: 'Mostrar el grupo',
    hideGroup: 'Ocultar el grupo',
    hideVariable: 'Ocultar la variable',
    hideVariableTy: `Masquer la variable sur l'aperçu graphique`,
    displayVariable: 'Mostrar la variable',
    disableVariable: 'Desactivar la variable',
    enableVariable: 'Activar la variable',
    displayVariableTy: `Afficher la variable sur l'aperçu graphique`,
    deleteVariable: 'Vaciar la variable',
    duplicateVariable: 'Duplicar la variable',
    undoPeriod: 'Regresar al período seleccionado anteriormente',
    redoPeriod: 'Ir al período seleccionado siguiente',
    editVariable: 'Modificar la variable',
    deleteTab: 'Vaciar la pestaña',
    queryLanguageEnabled: 'Una pestaña por hijo del bloque. Configurar...',
    queryLanguageDisabled: 'Una pestaña para el bloque. Configurar...',
    deletePeriod: 'Vaciar el período',
    editPeriod: 'Modificar el período',
    addVariableToChart: 'Agregar la variable al gráfico',
    deleteInfluencingVariable: 'Quitar la variable de la lista',
    openInPlayground: 'Abrir en un playground',
    addStyledRule: 'Agregar una regla',
    saveStyleConfiguration: 'Guardar estas reglas en una lista',
    addStyleConfiguration: 'Utilizar una lista de reglas',
    fromBarChart: 'Transformar en un gráfico lineal',
    fromLineChart: 'Transformar en un gráfico de barras',
  },
  alert: {
    liveChartCustomTimeRange: 'El intervalo de tiempo de un gráfico en vivo no es modificable y corresponde a los últimos 5 minutos.',
    computationStats_CUSTOMPERIOD: 'Las estadísticas no están disponibles en modo multiperíodos.',
    modelisation: 'El modelado no está disponible para un cálculo.',
    threshold: 'El análisis de umbral no está disponible para un cálculo.',
  },
  xgrid: {
    noData: 'Ningún subperíodo',
    footerPagination: 'Subperíodos por página',
    footerTotalRun: 'Total de subperíodos',
  },
};
